import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mt-2 justify-content-center"
}
const _hoisted_2 = {
  key: 1,
  class: "row mt-2 justify-content-center"
}
const _hoisted_3 = {
  key: 2,
  class: "row mt-2 justify-content-center"
}
const _hoisted_4 = {
  key: 3,
  class: "row mt-2 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!
  const _component_chart_statistic = _resolveComponent("chart-statistic")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Статистика по методам",
    class: "logs-statistics-page",
    selectSource: false
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_filter_component, {
        filter: _ctx.filterModel,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterModel) = $event)),
        onFilterData: _ctx.onclickFilterChange,
        selectInterval: false,
        methods: false,
        logsType: false,
        logsLevel: false,
        url: false,
        requestId: false,
        loggerName: false,
        shortMessage: false,
        liveReload: true,
        onOnLiveReload: _ctx.liveReload
      }, null, 8, ["filter", "onFilterData", "onOnLiveReload"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
      _createVNode(_component_chart_statistic, {
        chartModel: _ctx.chartModel1,
        type: 'line'
      }, null, 8, ["chartModel"]),
      (!_ctx.error1.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error1.errorMessage), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_chart_statistic, {
        chartModel: _ctx.chartModel2,
        type: 'line'
      }, null, 8, ["chartModel"]),
      (!_ctx.error2.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error2.errorMessage), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_chart_statistic, {
        chartModel: _ctx.chartModel3,
        type: 'line'
      }, null, 8, ["chartModel"]),
      (!_ctx.error3.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error3.errorMessage), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_chart_statistic, {
        chartModel: _ctx.chartModel4,
        type: 'line'
      }, null, 8, ["chartModel"]),
      (!_ctx.error4.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error4.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}